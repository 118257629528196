// assets
import IconGift from "../assets/icons/gift.svg";
import IconReturn from "../assets/icons/return.svg";
import IconShipping from "../assets/icons/shipping.svg";

export const promos = [
  {
    title: "FREE OVERNIGHT SHIPPING",
    text: "Free overnight shipping on all memo orders",
  },
  {
    title: "LIFETIME TRADE UP/DOWN",
    text: "Trade up or trade down, for life, any items from our inventory",
  },
  {
    title: "30 DAY RETURNS",
    text: "Return anything for a full refund within 30 days after purchasing",
  },
  {
    title: "OUR ADVANTAGE",
    text: "We make buying diamonds on memo easy and fast, with little to no risk to you",
  },
];
