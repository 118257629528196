// packages
import { useState, useEffect } from "react";
import _ from "lodash";
import classNames from "classnames";
import { MdOutlineGridView, MdOutlineTableRows } from "react-icons/md";
import Select from "react-select";

// classes
import Diamond from "../classes/Diamond";

// components
import Compare from "./Compare";
import ItemGrid from "./ItemGrid";
import Pagination from "./Pagination";
import PickItems from "./PickItems";
import RangeNumeric from "./RangeNumeric";
import RangeValues from "./RangeValues";
import SelectImages from "./SelectImages";
import Table from "./Table";
import Tabs from "./Tabs";

// contexts
import { useComparison } from "../contexts/ComparisonContext";
import { useSearch } from "../contexts/SearchContext";

// utils
import { formattedCurrency } from "../utils/currency";
import { isClientUser } from "../utils/user";
import { isTokenValid } from "../utils/auth";

const DEFAULT_THEME = {
  button: {
    primary: "bg-gray-600 text-white hover:bg-theme-blue-logo hover:text-white",
  },
  compare: {
    button: {
      primary:
        "bg-gray-600 text-white hover:bg-theme-blue-logo hover:text-white",
    },
  },
  input: "border-gray-600 focus:border-theme-blue-logo",
  multiselect: {
    title: "text-black",
    select: {
      control: ({ isDisabled, isFocused }) =>
        classNames(
          !isDisabled && isFocused && "border-theme-blue-logo",
          isFocused && "shadow-[0_0_0_1px] shadow-theme-blue-logo",
          isFocused && "hover:border-theme-blue-logo",
        ),
      option: ({ isDisabled, isFocused, isSelected }) =>
        classNames(
          isSelected && "bg-theme-blue-logo",
          !isSelected && isFocused && "bg-theme-blue-logo text-white",
          !isDisabled && isSelected && "active:bg-theme-blue-logo",
          !isDisabled && !isSelected && "active:bg-theme-blue-logo",
        ),
    },
  },
  radio:
    "border-gray-400 bg-gray-50 hover:bg-gray-200 checked:bg-theme-blue-logo checked:hover:bg-theme-blue-logo/50 checked:focus:bg-theme-blue-logo/50",
  rangeNumeric: {
    input: "border-gray-600 focus:border-theme-blue-logo",
    mark: "bg-theme-blue-logo",
    thumb: "bg-theme-blue-logo text-white focus:ring-theme-blue-logo/40",
    title: "text-black",
    track: {
      active: "bg-theme-blue-logo",
      inactive: "bg-gray-400",
    },
  },
  rangeValues: {
    label: {
      active: "text-black",
      inactive: "text-gray-400",
    },
    mark: "bg-theme-blue-logo",
    thumb: "bg-theme-blue-logo text-white focus:ring-theme-blue-logo/40",
    title: "text-black",
    track: {
      active: "bg-theme-blue-logo",
      inactive: "bg-gray-400",
    },
  },
  selectImages: {
    option: {
      all: "",
      active: "border-theme-blue-logo bg-gray-50",
      hover: "hover:bg-gray-100",
      inactive: "border-white",
    },
    title: "text-black",
  },
};

export default function DiamondsUI({ theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  //////////////////////////////////////////////////////////////////////////////
  // contexts
  //////////////////////////////////////////////////////////////////////////////
  const {
    // data
    results,

    // search
    searchParams,
    setSearchParams,
    setCurrentPage,
    searchProperties,
    // handleSearch,
    handleInputChange,
    fetchSearchProperties,
    expandAdvancedSearch,

    // reset
    handleReset,

    // sort
    // handleSort,

    // pagination
    currentPage,
    totalPages,
    handlePageChange,

    // routes
    routeShow,
  } = useSearch();

  const { comparisonData } = useComparison();

  //////////////////////////////////////////////////////////////////////////////
  // search
  //////////////////////////////////////////////////////////////////////////////

  const [paramDescription, setParamDescription] = useState(
    searchParams.description,
  );

  const handleParamDescription = (e) => {
    const previousValue = e.target.getAttribute("data-previous-value") || "";

    if (previousValue !== paramDescription) {
      setSearchParams((prevParams) => ({
        ...prevParams,
        description: paramDescription,
      }));
      setCurrentPage(1);
      handleInputChange(e);
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  // user
  //////////////////////////////////////////////////////////////////////////////

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // const profile = JSON.parse(localStorage.profile);
    // setUserRole(profile.role);
    // fetchSearchProperties(profile.company_id);
    fetchSearchProperties(process.env.REACT_APP_COMPANY_ID);
  }, []);

  //////////////////////////////////////////////////////////////////////////////
  // table columns
  //////////////////////////////////////////////////////////////////////////////

  const tableColumns = () => {
    let columns = [
      {
        key: "stockNumber",
        label: "Stock #",
        sortKey: "item_no",
        link: true,
        path: routeShow,
      },
      {
        key: "shapeDetails",
        label: "Shape",
        sortKey: "shape_code",
        classNames: "text-center",
      },
    ];

    // if (
    //   searchParams.stoneType // &&
    //   // searchParams.stoneType !== "DIAM" &&
    //   // searchParams.stoneType !== "LGD"
    // ) {
    //   columns.push({
    //     key: "stoneType",
    //     label: "Type",
    //     sortKey: "stone_type",
    //   });
    // }

    if (searchParams.description) {
      columns.push({
        key: "stockNumber",
        label: "Stock #",
        sortKey: "item_no",
      });
    }

    columns.push(
      {
        key: "shape",
        label: "Shape",
        sortKey: "shape_name",
        link: true,
        path: routeShow,
        classNames: "text-center",
      },
      {
        key: "carats",
        label: "Carats",
        sortKey: "carats",
        classNames: "text-right",
      },
      {
        key: "color",
        label: "Color",
        sortKey: "color_code",
        classNames: "text-center",
      },
      {
        key: "clarity",
        label: "Clarity",
        sortKey: "clarity_code",
        classNames: "text-center",
      },
      {
        key: "cut",
        label: "Cut",
        sortKey: "cut_code",
        classNames: "text-center",
      },
      // {
      //   key: "measurements",
      //   label: "Measurements",
      //   sortKey: "measurements",
      // },
    );

    if (searchParams.polish && searchParams.polish.length > 0) {
      columns.push({
        key: "polish",
        label: "Polish",
        sortKey: "polish_code",
        classNames: "text-center",
      });
    }

    if (searchParams.symmetry && searchParams.symmetry.length > 0) {
      columns.push({
        key: "symmetry",
        label: "Symmetry",
        sortKey: "symmetry_code",
        classNames: "text-center",
      });
    }

    if (searchParams.fluorescence && searchParams.fluorescence.length > 0) {
      columns.push({
        key: "fluorescence",
        label: "Fluorescence",
        sortKey: "fluorescence_code",
        classNames: "text-center",
      });
    }

    if (searchParams.tableMin || searchParams.tableMax) {
      columns.push({
        key: "table",
        label: "Table",
        sortKey: "table",
        classNames: "text-center",
      });
    }

    if (searchParams.depthMin || searchParams.depthMax) {
      columns.push({
        key: "depth",
        label: "Depth",
        sortKey: "depth",
        classNames: "text-center",
      });
    }

    if (searchParams.lab && searchParams.lab.length > 0) {
      columns.push({ key: "lab", label: "Lab", sortKey: "lab_name" });
    }

    // TODO make logic for authentication
    if (isTokenValid()) {
      columns.push({
        key: "price",
        label: "Price",
        sortKey: "price_total_2",
        classNames: "text-right",
      });
    }

    return columns;
  };

  //////////////////////////////////////////////////////////////////////////////
  // item details
  //////////////////////////////////////////////////////////////////////////////

  const detailSettings = {
    keys: [
      "stockNumber",
      "stoneType",
      "lab",
      "certificate",
      "origin",
      "polish",
      "symmetry",
      "fluorescence",
      "depth",
      "table",
      "measurements",
      "size",
      "ratio",
      "culet",
      "girdle",
    ],
  };

  // const modalSettings = {
  //   detailsLinkPath: "diamonds",
  //   columns: [
  //     { key: "item_no", header: true },
  //     { key: "carats", label: "Carats" },
  //     { key: "clarity", label: "Clarity" },
  //     { key: "color", label: "Color" },
  //     { key: "comments", label: "Comments" },
  //     { key: "cut", label: "Cut" },
  //     { key: "description", label: "Description" },
  //     { key: "lab", label: "Lab" },
  //     { key: "price", label: "Price" },
  //     { key: "shape", label: "Shape" },
  //   ],
  // };

  //////////////////////////////////////////////////////////////////////////////
  // view switcher
  //
  // TODO: refactor into component to remove duplication
  //////////////////////////////////////////////////////////////////////////////

  const [viewType, setViewType] = useState(
    localStorage.getItem("viewType") || "table",
  );

  useEffect(() => {
    localStorage.setItem("viewType", viewType);
  }, [viewType]);

  const paginationAndViewSwitcher = (
    <div className="flex justify-between">
      <Pagination
        currentPage={currentPage - 1}
        onPageChange={handlePageChange}
        pageCount={totalPages}
      />
      <div className="inline-flex">
        <button>
          <MdOutlineGridView
            className={`${viewType === "grid" && "text-theme-blue-logo"} shrink-0`}
            onClick={() => setViewType("grid")}
            size={24}
          />
        </button>
        <button>
          <MdOutlineTableRows
            className={`${viewType === "table" && "text-theme-blue-logo"} shrink-0`}
            onClick={() => setViewType("table")}
            size={24}
          />
        </button>
      </div>
    </div>
  );

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const tabData = [
    {
      label: "Search",
      content: (
        <div>
          <div className="my-8">
            <div className="my-4">
              <SelectImages
                title="Shape"
                name="shape"
                selectedValues={searchParams.shape}
                theme={activeTheme.selectImages}
                onChange={(e) => {
                  setSearchParams((prevParams) => ({
                    ...prevParams,
                    shape: e.target.value,
                  }));
                  setCurrentPage(1);
                }}
                data={Diamond.Shapes()}
              />
            </div>
          </div>
          <div className="my-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
            <PickItems
              title="Cut"
              options={Diamond.Cuts()}
              selectedValues={searchParams.cut}
              callback={(value) => {
                const newCutValues = _.includes(searchParams.cut, value)
                  ? _.without(searchParams.cut, value)
                  : [...searchParams.cut, value];
                setSearchParams((prevParams) => ({
                  ...prevParams,
                  cut: newCutValues,
                }));
                setCurrentPage(1);
              }}
            />
            <PickItems
              title="Color"
              theme={{ gridClass: "grid-cols-6" }}
              options={Diamond.Colors()}
              selectedValues={searchParams.color}
              callback={(value) => {
                const newColorValues = _.includes(searchParams.color, value)
                  ? _.without(searchParams.color, value)
                  : [...searchParams.color, value];

                setSearchParams((prevParams) => ({
                  ...prevParams,
                  color: newColorValues,
                }));
                setCurrentPage(1);
              }}
            />
            <PickItems
              title="Clarity"
              options={Diamond.Clarities()}
              selectedValues={searchParams.clarity}
              theme={{ gridClass: "grid-cols-6" }}
              callback={(value) => {
                const newClarityValues = _.includes(searchParams.clarity, value)
                  ? _.without(searchParams.clarity, value)
                  : [...searchParams.clarity, value];

                setSearchParams((prevParams) => ({
                  ...prevParams,
                  clarity: newClarityValues,
                }));
                setCurrentPage(1);
              }}
            />
            <RangeNumeric
              title="Carats"
              limitMax={searchProperties.carats_max}
              limitMin={searchProperties.carats_min}
              step={0.01}
              theme={activeTheme.rangeNumeric}
              valMax={searchParams.caratsMax}
              valMin={searchParams.caratsMin}
              callback={(min, max) => {
                setSearchParams((prevParams) => ({
                  ...prevParams,
                  caratsMin: min,
                  caratsMax: max,
                }));
                setCurrentPage(1);
              }}
            />
          </div>
          <div className="mt-2 flex flex-col gap-4 md:mt-4 md:flex-row">
            <aside className="flex h-auto flex-col gap-6 md:w-1/3 lg:w-1/4 2xl:w-1/5">
              <button
                className={`${activeTheme.button.primary} p-1.5 text-sm`}
                onClick={handleReset}
              >
                Reset Search
              </button>

              {/* TODO: fix valueCalculator and positionCalculator */}
              {/* TODO:logic for show price range only for loged user*/}
              {/* <RangeNumeric
              title="Price"
              limitMax={searchProperties.price_max}
              limitMin={searchProperties.price_min}
              // markMax={239}
              // markMin={0}
              // marks={[10, 109, 149]}
              theme={activeTheme.rangeNumeric}
              valMax={searchParams.priceMax}
              valMin={searchParams.priceMin}
              callback={(min, max) => {
                setSearchParams((prevParams) => ({
                  ...prevParams,
                  priceMin: min,
                  priceMax: max,
                }));
                setCurrentPage(1);
              }}
              valueFormatter={(value) => {
                return formattedCurrency(value, 0);
              }}
              // valueCalculator={(number) => {
              //   if (number <= 10) {
              //     // increase by $100 up to $1,000
              //     return number * 100;
              //   } else if (number <= 109) {
              //     // increase by $1,000 up to $100,000
              //     return 1000 + (number - 10) * 1000;
              //   } else if (number <= 149) {
              //     // increase by $10,000 up to $500,000
              //     return 100_000 + (number - 109) * 10_000;
              //   } else if (number <= 239) {
              //     // increase by $50,000 up to $5,000,000
              //     return 500_000 + (number - 149) * 50_000;
              //   }
              // }}
              // positionCalculator={(value) => {
              //   if (value <= 1000) {
              //     // Convert $100 increments up to $1000
              //     return value / 100;
              //   } else if (value <= 100000) {
              //     // Convert $1000 increments up to $100,000
              //     return 10 + (value - 1000) / 1000;
              //   } else if (value <= 500000) {
              //     // Convert $10,000 increments up to $500,000
              //     return 109 + (value - 100000) / 10000;
              //   } else if (value <= 5000000) {
              //     // Convert $50,000 increments up to $5,000,000
              //     return 149 + (value - 500000) / 50000;
              //   } else {
              //     // Set the position to the maximum mark if value exceeds the maximum
              //     return 239;
              //   }
              // }}
            /> */}

              <div className="mb-2 flex flex-col gap-6">
                <div>
                  <p
                    className={`${activeTheme.multiselect.title} mb-2 text-sm font-bold`}
                  >
                    Lab
                  </p>
                  <Select
                    isClearable={true}
                    isMulti={true}
                    classNames={activeTheme.multiselect.select}
                    onChange={(values, _action) => {
                      setSearchParams((prevParams) => ({
                        ...prevParams,
                        lab: values.map((item) => item.value),
                      }));
                      setCurrentPage(1);
                    }}
                    options={
                      searchProperties.lab &&
                      searchProperties.lab.map((item) => ({
                        label: item,
                        value: item,
                      }))
                    }
                    placeholder="Lab"
                    value={searchParams.lab.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </div>
                <div className="mt-2">
                  <p className="text-sm font-bold">
                    Item Number or Description
                  </p>
                  <input
                    className={`${activeTheme.input} mt-2 w-full text-sm focus:ring-0`}
                    data-previous-value=""
                    name="description"
                    onBlur={(e) => {
                      handleParamDescription(e);
                    }}
                    onChange={(e) => {
                      setParamDescription(e.target.value);
                    }}
                    onFocus={(e) => {
                      e.target.setAttribute(
                        "data-previous-value",
                        e.target.value,
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        handleParamDescription(e);
                      }
                    }}
                    placeholder="Search by item number or description"
                    type="text"
                    value={paramDescription}
                  />
                </div>
                <PickItems
                  title="Polish"
                  options={Diamond.Polishes()}
                  selectedValues={searchParams.polish}
                  theme={{
                    gridClass: "grid-cols-5",
                    textClass: "text-2xs sm:text-3xs lg:text-2xs",
                  }}
                  callback={(value) => {
                    const newPolishValues = _.includes(
                      searchParams.polish,
                      value,
                    )
                      ? _.without(searchParams.polish, value)
                      : [...searchParams.polish, value];

                    setSearchParams((prevParams) => ({
                      ...prevParams,
                      polish: newPolishValues,
                    }));
                    setCurrentPage(1);
                  }}
                />
                <PickItems
                  title="Symmetry"
                  options={Diamond.Symmetries()}
                  theme={{
                    gridClass: "grid-cols-5",
                    textClass: "text-2xs sm:text-3xs lg:text-2xs",
                  }}
                  selectedValues={searchParams.symmetry}
                  callback={(value) => {
                    const newSymmetryValues = _.includes(
                      searchParams.symmetry,
                      value,
                    )
                      ? _.without(searchParams.symmetry, value)
                      : [...searchParams.symmetry, value];

                    setSearchParams((prevParams) => ({
                      ...prevParams,
                      symmetry: newSymmetryValues,
                    }));
                    setCurrentPage(1);
                  }}
                />
                <PickItems
                  title="Fluorescence"
                  options={Diamond.Fluorescences()}
                  theme={{
                    gridClass: "grid-cols-5",
                    textClass: "text-2xs sm:text-3xs lg:text-2xs",
                  }}
                  selectedValues={searchParams.fluorescence}
                  callback={(value) => {
                    const newFluorescenceValues = _.includes(
                      searchParams.fluorescence,
                      value,
                    )
                      ? _.without(searchParams.fluorescence, value)
                      : [...searchParams.fluorescence, value];

                    setSearchParams((prevParams) => ({
                      ...prevParams,
                      fluorescence: newFluorescenceValues,
                    }));
                    setCurrentPage(1);
                  }}
                />
                <RangeNumeric
                  title="Table (%)"
                  limitMax={searchProperties.table_max}
                  limitMin={searchProperties.table_min}
                  step={0.1}
                  theme={activeTheme.rangeNumeric}
                  valMax={searchParams.tableMax}
                  valMin={searchParams.tableMin}
                  callback={(min, max) => {
                    setSearchParams((prevParams) => ({
                      ...prevParams,
                      tableMin: min,
                      tableMax: max,
                    }));
                    setCurrentPage(1);
                  }}
                />
                <RangeNumeric
                  title="Depth (%)"
                  limitMax={searchProperties.depth_max}
                  limitMin={searchProperties.depth_min}
                  step={0.1}
                  theme={activeTheme.rangeNumeric}
                  valMax={searchParams.depthMax}
                  valMin={searchParams.depthMin}
                  callback={(min, max) => {
                    setSearchParams((prevParams) => ({
                      ...prevParams,
                      depthMin: min,
                      depthMax: max,
                    }));
                    setCurrentPage(1);
                  }}
                />
              </div>
            </aside>
            <main className="flex-1 overflow-x-auto">
              {paginationAndViewSwitcher}
              {viewType === "grid" && <ItemGrid data={results} />}
              {viewType === "table" && (
                <Table
                  data={results}
                  columns={tableColumns().filter(
                    (column) => column.key !== "shape",
                  )}
                  quoteType={isClientUser(userRole) && "diamond"}
                  enableSorting={true}
                  showBookmarks={false}
                />
              )}
              {paginationAndViewSwitcher}
            </main>
          </div>
        </div>
      ),
    },
    {
      label: `Compare (${comparisonData.length})`,
      content: (
        <Compare
          columns={tableColumns().filter(
            (column) => column.key !== "shapeDetails",
          )}
          theme={activeTheme.compare}
        />
      ),
    },
  ];

  return <Tabs data={tabData} />;
}
