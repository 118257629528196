// assets
import ShapeIconAsscher from "../assets/images/diamonds/shapes/icons/asscher.webp";
import ShapeIconCushion from "../assets/images/diamonds/shapes/icons/cushion.webp";
import ShapeIconEmerald from "../assets/images/diamonds/shapes/icons/emerald.webp";
import ShapeIconHeart from "../assets/images/diamonds/shapes/icons/heart.webp";
import ShapeIconMarquise from "../assets/images/diamonds/shapes/icons/marquise.webp";
import ShapeIconOval from "../assets/images/diamonds/shapes/icons/oval.webp";
import ShapeIconPear from "../assets/images/diamonds/shapes/icons/pear.webp";
import ShapeIconPrincess from "../assets/images/diamonds/shapes/icons/princess.webp";
import ShapeIconRadiant from "../assets/images/diamonds/shapes/icons/radiant.webp";
import ShapeIconRound from "../assets/images/diamonds/shapes/icons/round.webp";

import ShapePhotoAsscher from "../assets/images/diamonds/shapes/photos/large/asscher.jpg";
import ShapePhotoCushion from "../assets/images/diamonds/shapes/photos/large/cushion.jpg";
import ShapePhotoEmerald from "../assets/images/diamonds/shapes/photos/large/emerald.jpg";
import ShapePhotoHeart from "../assets/images/diamonds/shapes/photos/large/heart.jpg";
import ShapePhotoMarquise from "../assets/images/diamonds/shapes/photos/large/marquise.jpg";
import ShapePhotoOval from "../assets/images/diamonds/shapes/photos/large/oval.jpg";
import ShapePhotoPear from "../assets/images/diamonds/shapes/photos/large/pear.jpg";
import ShapePhotoPrincess from "../assets/images/diamonds/shapes/photos/large/princess.jpg";
import ShapePhotoRadiant from "../assets/images/diamonds/shapes/photos/large/radiant.jpg";
import ShapePhotoRound from "../assets/images/diamonds/shapes/photos/large/round.jpg";

import ShapeThumbAsscher from "../assets/images/diamonds/shapes/photos/thumb/asscher.jpg";
import ShapeThumbCushion from "../assets/images/diamonds/shapes/photos/thumb/cushion.jpg";
import ShapeThumbEmerald from "../assets/images/diamonds/shapes/photos/thumb/emerald.jpg";
import ShapeThumbHeart from "../assets/images/diamonds/shapes/photos/thumb/heart.jpg";
import ShapeThumbMarquise from "../assets/images/diamonds/shapes/photos/thumb/marquise.jpg";
import ShapeThumbOval from "../assets/images/diamonds/shapes/photos/thumb/oval.jpg";
import ShapeThumbPear from "../assets/images/diamonds/shapes/photos/thumb/pear.jpg";
import ShapeThumbPrincess from "../assets/images/diamonds/shapes/photos/thumb/princess.jpg";
import ShapeThumbRadiant from "../assets/images/diamonds/shapes/photos/thumb/radiant.jpg";
import ShapeThumbRound from "../assets/images/diamonds/shapes/photos/thumb/round.jpg";

// utils
import { formattedCurrency } from "../utils/currency";

export default class Diamond {
  //////////////////////////////////////////////////////////////////////////////
  // public
  //////////////////////////////////////////////////////////////////////////////

  constructor(data, showPath = null) {
    this.data = data;
    this.route = showPath ? `${showPath}/${data.id}` : data.id;

    this.id = data.id;
    this.type = data.type;
    this.is_bookmarked = data.is_bookmarked;

    // primary
    this.stockNumber = data.item_no;
    this.title = data.description
      ? data.description
      : `${this.#formatCarats()} Carat ${data.shape_code} Diamond`;
    this.summary = data.description_2
      ? data.description_2
      : `${data.cut_code && data.cut_code} Cut • ${data.color_code} Color • ${data.clarity_code} Clarity`;
    this.stoneType = data.stone_type_human;
    this.shape = data.shape_name;
    this.shapeCode = data.shape_code;
    this.shapeData = Diamond.ShapeData(data);
    this.carats = this.#formatCarats();
    this.inventory = data.inventory_type;
    this.stones = data.stones;
    this.color = data.color_code;
    this.clarity = data.clarity_code;
    this.cut = data.cut_code;

    // secondary
    this.polish = data.polish_code;
    this.symmetry = data.symmetry_code;
    this.fluorescence = data.fluorescence_code;
    this.measurements = data.measurements;
    this.ratio = data.ratio;
    this.depth = `${data.depth}%`;
    this.table = `${data.table}%`;
    this.size = data.size;

    // tertiary
    this.culet = data.culet;
    this.girdle = data.girdle;
    this.crown_angle = data.crown_angle;
    this.crown_height = data.crown_height;
    this.pavilion_angle = data.pavilion_angle;
    this.pavilion_depth = data.pavilion_depth;

    //  color
    this.isFancy = data.is_fancy;
    this.fancyColor = data.fancy_color_name;
    this.fancyIntensity = data.fancy_intensity;
    this.fancyOvertone = data.fancy_overtone;
    this.inclusionBlack = data.inclusion_black;
    this.inclusionCenter = data.inclusion_center;

    // lab
    this.lab = data.lab_name;
    this.certificate = data.lab_cert_no;
    this.certificateUrl = data.certificate_url;

    // price
    this.price = formattedCurrency(data.price_total_2, 0);
    this.priceCt = formattedCurrency(data.price_per_carat_2, 0);

    // locations
    this.origin = data.origin;

    // assets
    this.videoUrl = data.video_url;
    this.webUrl = data.web_url;
    this.images = {
      main: { alt: this.title, src: this.#imagePath() },
      thumb: { alt: this.title, src: this.#imagePath(true) },

      // TODO
      // certificate: { alt: this.title, src: data.certificate_1_filename },
      // gallery: [
      //   { alt: this.title, src: data.image_1_filename },
      //   { alt: this.title, src: data.image_2_filename },
      //   { alt: this.title, src: data.image_3_filename },
      //   { alt: this.title, src: data.image_4_filename },
      // ],
    };

    this.overview = {
      stockNumber: this.stockNumber,
      gemstone: this.stoneType,
      origin: this.origin,
      priceCt: this.priceCt,
      carats: this.carats,
      shape: this.shape,
      color: this.color,
      clarity: this.clarity,
      cut: this.cut,
      measurements: this.measurements,
      table: this.table,
      depth: this.depth,
      symmetry: this.symmetry,
      polish: this.polish,
      girdle: this.girdle,
      culet: this.culet,
      fluorescence: this.fluorescence,
    };

    this.shapeDetails = (
      <div className="flex items-center gap-1 md:flex-row">
        {this.shapeData && (
          <img
            alt={this.title}
            className="h-6 object-cover"
            src={this.shapeData.icon}
          />
        )}
        {this.data.shape_code}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  // private
  //////////////////////////////////////////////////////////////////////////////

  #imagePath(thumb = false) {
    if (this.data.images && this.data.images[0]) {
      return this.data.images[0].url;
    }

    // TODO
    // if (this.data.image_1_filename) {
    //   return `${process.env.REACT_APP_CLOUDFRONT_URL}/stones/images/${this.data.image_1_filename}`;
    // }

    if (this.shapeData) {
      if (thumb) {
        return this.shapeData.photos.thumb;
      } else {
        return this.shapeData.photos.main;
      }
    }
  }

  #formatCarats() {
    let carats = "";

    if (this.data.carats) {
      let caratsAsFloat = parseFloat(this.data.carats);

      if (typeof caratsAsFloat === "number") {
        carats = caratsAsFloat.toFixed(2);
      }
    }

    return carats;
  }

  //////////////////////////////////////////////////////////////////////////////
  // static
  //////////////////////////////////////////////////////////////////////////////

  static ShapeData(item) {
    return Diamond.Shapes().find((data) => data.value === item.shape_code);
  }

  // definitions
  // TODO: replace with search properties from the API

  static Shapes() {
    return [
      {
        label: "Round",
        value: "RD",
        icon: ShapeIconRound,
        photos: { main: ShapePhotoRound, thumb: ShapeThumbRound },
      },
      {
        label: "Oval",
        value: "OV",
        icon: ShapeIconOval,
        photos: { main: ShapePhotoOval, thumb: ShapeThumbOval },
      },
      {
        label: "Cushion",
        value: "CU",
        icon: ShapeIconCushion,
        photos: { main: ShapePhotoCushion, thumb: ShapeThumbCushion },
      },
      {
        label: "Pear",
        value: "PR",
        icon: ShapeIconPear,
        photos: { main: ShapePhotoPear, thumb: ShapeThumbPear },
      },
      {
        label: "Princess",
        value: "PS",
        icon: ShapeIconPrincess,
        photos: { main: ShapePhotoPrincess, thumb: ShapeThumbPrincess },
      },
      {
        label: "Emerald",
        value: "EC",
        icon: ShapeIconEmerald,
        photos: { main: ShapePhotoEmerald, thumb: ShapeThumbEmerald },
      },
      {
        label: "Marquise",
        value: "MQ",
        icon: ShapeIconMarquise,
        photos: { main: ShapePhotoMarquise, thumb: ShapeThumbMarquise },
      },
      {
        label: "Asscher",
        value: "AS",
        icon: ShapeIconAsscher,
        photos: { main: ShapePhotoAsscher, thumb: ShapeThumbAsscher },
      },
      {
        label: "Radiant",
        value: "RA",
        icon: ShapeIconRadiant,
        photos: { main: ShapePhotoRadiant, thumb: ShapeThumbRadiant },
      },
      {
        label: "Heart",
        value: "HS",
        icon: ShapeIconHeart,
        photos: { main: ShapePhotoHeart, thumb: ShapeThumbHeart },
      },
    ];
  }

  static Cuts() {
    return {
      Ideal: "I",
      Excellent: "EX",
      "Very Good": "VG",
      Good: "G",
      Fair: "F",
      Poor: "P",
    };
  }

  static Colors() {
    return {
      // YZ: "YZ",
      // WX: "WX",
      // UV: "UV",
      // ST: "ST",
      // QR: "QR",
      // OP: "OP",
      D: "D",
      E: "E",
      F: "F",
      G: "G",
      H: "H",
      I: "I",
      J: "J",
      K: "K",
      L: "L",
      M: "M",
      N: "N",
    };
  }

  static Clarities() {
    return {
      FL: "FL",
      IF: "IF",
      VVS1: "VVS1",
      VVS2: "VVS2",
      VS1: "VS1",
      VS2: "VS2",
      SI1: "SI1",
      SI2: "SI2",
      I1: "I1",
      I2: "I2",
      I3: "I3",
    };
  }

  static Polishes() {
    return {
      Poor: "P",
      Fair: "F",
      Good: "G",
      "Very Good": "VG",
      Excellent: "EX",
    };
  }

  static Symmetries() {
    return {
      Poor: "P",
      Fair: "F",
      Good: "G",
      "Very Good": "VG",
      Excellent: "EX",
    };
  }

  static Fluorescences() {
    return {
      None: "NON",
      Faint: "FNT",
      Medium: "MED",
      Strong: "STG",
      "Very Strong": "VST",
    };
  }
}
