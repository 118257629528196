// packages
import { NavLink } from "react-router-dom";

// components
import StandardContainer from "./StandardContainer";

export default function HeroWithImage({
  title,
  subtitle,
  description,
  image,
  primaryButton,
  secondaryButton,
  stats,
}) {
  // https://tailwindui.com/components/marketing/sections/header#component-813ce86310c2c337070a66a152012665

  return (
    <div className="relative isolate overflow-hidden py-6 sm:py-8 lg:py-12 xl:py-16">
      <img
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right"
        src={image.src}
        style={image.cssStyles}
      />
      <StandardContainer
        content={
          <>
            {title && (
              // <h2 className="max-w-4xl text-4xl tracking-tight text-white border-none highlight highlight-black highlight-spread-y-sm highlight-spread-x-lg highlight-variant-17 sm:text-5xl md:text-6xl lg:text-6xl">
              <h2 className="max-w-xl border-none bg-white/60 p-2 text-2xl tracking-wide text-black sm:text-2xl md:text-2xl lg:text-3xl">
                {title}
              </h2>
            )}
            {subtitle && (
              // <h3 className="max-w-4xl mt-4 mb-2 text-xl font-semibold text-white border-none shadow-gray-700 text-shadow-sm md:text-2xl lg:text-3xl">
              <h3 className="mb-2 mt-4 max-w-xl  border-none bg-white/60 p-2 text-xl font-semibold text-black md:text-2xl lg:text-xl">
                {subtitle}
              </h3>
            )}
            {description && (
              // <h4 className="max-w-3xl text-xl leading-snug text-white">
              <h4 className="max-w-3xl text-xl leading-snug">{description}</h4>
            )}
            {(primaryButton || secondaryButton || stats) && (
              <div className="mt-12 grid grid-cols-1 sm:grid-cols-2">
                {(primaryButton || secondaryButton) && (
                  <div className="grid grid-cols-1 gap-y-2 font-semibold text-white sm:grid-cols-2 sm:gap-4 md:gap-8 xl:grid-cols-4">
                    {primaryButton && (
                      <NavLink
                        className="group border-none bg-white px-3 py-2 text-lg text-theme-blue-logo backdrop-blur-sm transition-all duration-300 hover:bg-black/70 hover:text-white max-lg:w-full sm:px-4 sm:py-3 md:px-5 md:py-4 lg:px-6 lg:py-5"
                        exact="exact"
                        to={primaryButton.href}
                      >
                        {primaryButton.title}
                        <span
                          aria-hidden="true"
                          className="ml-1 inline-block group-hover:translate-x-1"
                        >
                          &rarr;
                        </span>
                      </NavLink>
                    )}
                    {secondaryButton && (
                      <NavLink
                        className="group border-none bg-white px-3 py-2 text-lg text-theme-blue-logo backdrop-blur-sm transition-all duration-300 hover:bg-black/70 hover:text-white max-lg:w-full sm:px-4 sm:py-3 md:px-5 md:py-4 lg:px-6 lg:py-5"
                        exact="exact"
                        to={secondaryButton.href}
                      >
                        {secondaryButton.title}
                        <span
                          aria-hidden="true"
                          className="ml-1 inline-block group-hover:translate-x-1"
                        >
                          &rarr;
                        </span>
                      </NavLink>
                    )}
                  </div>
                )}
                {stats && (
                  <dl className="mt-4 max-w-xl">
                    {stats.map((stat, index) => (
                      <NavLink
                        className="group flex flex-col-reverse gap-1 border-none bg-white/90 px-3 py-2 backdrop-blur-sm hover:bg-black/70"
                        exact="exact"
                        key={index}
                        to={stat.href}
                      >
                        <dt className="font-bold text-black group-hover:text-white sm:text-lg">
                          {stat.text}
                        </dt>
                        <dd className="text-3xl font-bold tracking-tight text-theme-blue-logo group-hover:text-white">
                          {stat.value}
                        </dd>
                      </NavLink>
                    ))}
                  </dl>
                )}
              </div>
            )}
          </>
        }
      />
    </div>
  );
}
