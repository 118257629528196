// components
import StandardContainer from "./StandardContainer";

// data
import { promos } from "../data/promos";

export default function Promos() {
  const content = (
    <div className="mb-2 grid grid-cols-1 gap-4 divide-gray-200 bg-gray-50 px-4  max-sm:gap-y-4 max-sm:divide-y sm:divide-x md:mb-4 md:grid-cols-2 lg:grid-cols-4">
      {promos.map((promo, index) => (
        <div
          className="flex flex-col items-center gap-4 pt-4 sm:px-4 sm:pb-4"
          key={index}
        >
          <p className="font-semibold text-theme-blue-logo max-md:text-sm">
            {promo.title}
          </p>
          <p className="text-center text-sm">{promo.text}</p>
        </div>
      ))}
    </div>
  );

  return <StandardContainer content={content} />;
}
