// packages
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

// classes
import Diamond from "../shared/classes/Diamond";
import Jewelry from "../shared/classes/Jewelry";

// components (shared)
import LoadingIndicator from "../shared/components/LoadingIndicator";

// components (template)
import AddToAny from "../components/item/AddToAny";
import DiamondExplainer from "../components/item/DiamondExplainer";
import Header from "../components/Header";
import ItemDetails from "../components/item/ItemDetails";
import ItemGallery from "../components/item/ItemGallery";
import JewelryReviews from "../components/item/JewelryReviews";
import OrderDetails from "../components/item/OrderDetails";
import SimilarItems from "../components/item/SimilarItems";
import StandardContainer from "../components/StandardContainer";
import Promos from "../components/Promos";

export default function Item({ type }) {
  //////////////////////////////////////////////////////////////////////////////
  // data
  //////////////////////////////////////////////////////////////////////////////

  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const apiUrl =
    type === "diamond"
      ? `${process.env.REACT_APP_API_URL}/stones/${id}.json`
      : `${process.env.REACT_APP_API_URL}/jewelries/${id}.json`;

  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((response) => {
        type === "diamond"
          ? setData(new Diamond(response.data, "/diamonds"))
          : setData(new Jewelry(response.data, "/jewelry"));
      })
      .catch((error) => {
        console.error("Error fetching item:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const content = data && (
    <>
      <div className="my-2 flex flex-col md:my-4 md:flex-row md:gap-x-4 lg:gap-x-8">
        <main className="md:w-3/5 lg:w-2/3">
          <ItemGallery data={data} />
          <OrderDetails data={data} />
          <SimilarItems type={type} />
        </main>
        <aside className="mt-2 md:mt-0 md:w-2/5 lg:w-1/3">
          <ItemDetails dataAsParam={data} />
        </aside>
      </div>
    </>
  );

  return (
    <>
      {loading ? (
        <LoadingIndicator message={`Loading ${type} details`} />
      ) : (
        <>
          <Header title={data.title} />
          <StandardContainer content={content} />
          <AddToAny />
        </>
      )}
    </>
  );
}
