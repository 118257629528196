// packages
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// assets
import Logo1 from "../assets/logos/logo-top.png";
import Logo2 from "../assets/logos/02.png";

// icons
import {
  MdLocalPhone,
  MdMenu,
  MdOutlineAccountCircle,
  MdOutlineEmail,
  MdOutlineWhatsapp,
} from "react-icons/md";

// data
import { meta } from "../data/meta";
import { navbar } from "../data/nav";

// utils
import { isTokenValid } from "../shared/utils/auth";
import { isCompanyUser, isSuperAdminUser } from "../shared/utils/user";

const PROFILE_KEY = "profile";
const TOKEN_KEY = "token";

export default function Navbar() {
  const [showLogo, setShowLogo] = useState(true);
  const [open, setOpen] = useState(false);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 0.2;
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const maxScrollableHeight = documentHeight - windowHeight;

      if (scrollTop / maxScrollableHeight > scrollThreshold) {
        setShowLogo(false);
      } else {
        setShowLogo(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showLogo]);

  //////////////////////////////////////////////////////////////////////////////
  // user
  //////////////////////////////////////////////////////////////////////////////

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    if (!isTokenValid()) {
      localStorage.removeItem(PROFILE_KEY);
    } else {
      setIsAuthenticated(true);

      if (localStorage.getItem(PROFILE_KEY)) {
        setProfile(JSON.parse(localStorage.getItem(PROFILE_KEY)));
      }
    }
  }, [localStorage.getItem(TOKEN_KEY)]);

  const handleLogout = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/logout`, {
        headers: {
          Authorization: localStorage.getItem(TOKEN_KEY),
        },
      });

      localStorage.removeItem(PROFILE_KEY);
      localStorage.removeItem(TOKEN_KEY);
      setProfile({});
      setIsAuthenticated(false);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      alert("Error signing out, please try again.");
    }
  };

  const userNavData = {
    signedIn: [
      {
        href: "/quotes",
        text: "Quotes",
      },
      {
        href: "/bookmarks",
        text: "Bookmarks",
      },
      {
        href: "/profile",
        text: "Profile",
      },
      ...(isCompanyUser(profile.role)
        ? [
            {
              href: "/company",
              text: "Company",
            },
          ]
        : []),
      ...(isSuperAdminUser(profile.role)
        ? [
            {
              href: "/company",
              text: "Company",
            },
          ]
        : []),
      ...(isCompanyUser(profile.role) || isSuperAdminUser(profile.role)
        ? [
            {
              href: "/users",
              text: "Users",
            },
          ]
        : []),
      {
        href: "#",
        text: "Sign Out",
        onClick: (e) => handleLogout(e),
      },
    ],
    signedOut: [
      // {
      //   href: "sign-in",
      //   text: "Sign In",
      // },
      // {
      //   href: "/sign-up",
      //   text: "Sign Up",
      // },
    ],
  };

  const renderUserNav = () => {
    const userNav = isAuthenticated
      ? userNavData.signedIn
      : userNavData.signedOut;

    return (
      <>
        <ul className="" aria-labelledby="user-menu-button">
          {userNav.map((item, index) => (
            <li key={index}>
              <NavLink
                className="block px-4 text-lg font-bold uppercase transition-all duration-200 hover:border-black hover:text-black"
                to={item.href}
                {...(item.onClick ? { onClick: item.onClick } : {})}
              >
                {item.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <>
      <nav
        className={`${
          showLogo
            ? "border-transparent"
            : "fixed top-0 border-gray-200 shadow-md"
        } z-50 w-full border-b-2 bg-white transition-all duration-0`}
      >
        <div className="flex items-center justify-between bg-theme-blue-logo px-6 py-2 text-white">
          <div className="flex gap-3 sm:gap-8">
            <a
              href="tel:+12139555900"
              className="relative flex items-center space-x-1 border-none text-white after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-white after:transition-all after:duration-200 hover:text-white hover:after:w-full sm:space-x-2"
            >
              <MdLocalPhone className="text-sm sm:text-lg" />
              <span className="hidden text-sm sm:block">(213) 955-5900</span>
              <span className="text-sm">Call</span>
            </a>
            <a
              href="https://wa.me/12138107333"
              target="_blank"
              className="relative flex cursor-pointer items-center space-x-1 border-none text-white after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-white after:transition-all after:duration-200 hover:border-none hover:text-white  hover:after:w-full sm:space-x-2"
            >
              <MdOutlineWhatsapp className="text-sm sm:text-lg" />
              <span className="text-sm">WhatsApp</span>
            </a>
          </div>
          <div className="flex items-center gap-x-2 ">
            <Link
              to="/contact"
              className="relative flex items-center space-x-1 border-none text-white after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-white after:transition-all after:duration-200 hover:border-none hover:text-white hover:after:w-full  max-sm:text-xs sm:space-x-2"
            >
              <MdOutlineEmail className="text-sm sm:text-lg" />
              <span className="text-sm">Contact</span>
            </Link>

            <button
              type="button"
              aria-expanded="false"
              onClick={() => setOpenUserMenu(!openUserMenu)}
            >
              {!isAuthenticated ? (
                <NavLink
                  to="/sign-in"
                  className="relative flex items-center space-x-1 border-none text-white after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-white after:transition-all after:duration-200 hover:border-none  hover:text-white hover:after:w-full  max-sm:text-xs sm:space-x-2"
                >
                  <MdOutlineAccountCircle className="text-sm sm:text-lg" />
                  <span className="text-sm">Sign In</span>
                </NavLink>
              ) : (
                <div className="flex items-center space-x-1 font-semibold text-white sm:space-x-2">
                  <MdOutlineAccountCircle className="text-sm sm:text-lg" />
                  <span className="text-sm">Account</span>
                </div>
              )}
            </button>

            <div
              className={`${!openUserMenu ? "hidden" : "block"} absolute right-3 top-10 divide-y divide-gray-100 rounded-md bg-white shadow`}
              onClick={() => setOpenUserMenu(!openUserMenu)}
            >
              {isAuthenticated && (
                <div className="px-4 py-3">
                  <span className="block text-sm font-semibold text-gray-800">
                    {profile.full_name}
                  </span>
                  <span className="block truncate text-sm text-gray-500">
                    {profile.email}
                  </span>
                </div>
              )}
              {renderUserNav()}
            </div>
          </div>
        </div>
        <NavLink to="/">
          <h1
            className={`${
              !showLogo ? "hidden" : "block"
            } flex items-center justify-center pb-4 pt-12 text-5xl font-semibold text-gray-700 hover:text-theme-blue-logo max-md:hidden max-md:flex-col`}
          >
            <img
              src={Logo1}
              alt={meta.name}
              className="max-w-80 object-contain"
            />
          </h1>
        </NavLink>
        <div className="flex flex-wrap items-center justify-between max-md:p-2 md:justify-center">
          <div className="flex items-center">
            <NavLink to="/">
              <img
                src={Logo2}
                alt={meta.name}
                className="w-1/2 object-contain md:hidden"
              />
            </NavLink>
            <button onClick={() => setOpen(!open)} className="md:hidden">
              <span className="sr-only">Toggle menu</span>
              <MdMenu size="32" className="shrink-0 text-theme-blue-logo" />
            </button>
          </div>
          <div
            className={`${open ? "max-md:h-28" : "max-md:h-0"} w-full overflow-hidden transition-all duration-300`}
          >
            <ul className="flex flex-col max-md:mt-2 max-md:space-y-1 md:flex-row md:justify-center md:space-x-8 md:py-2">
              {Object.keys(navbar).map((link, index) => {
                return (
                  <li key={index}>
                    <NavLink
                      className={({ isActive }) =>
                        `border-b-2 border-white text-lg font-bold uppercase transition-all duration-200 hover:border-black hover:text-black
                      ${isActive && "border-b-theme-blue-logo md:text-black"}`
                      }
                      exact="exact"
                      to={navbar[link].href}
                      onClick={() => {
                        if (window.innerWidth <= 768) {
                          setOpen(!open);
                        }
                      }}
                    >
                      {navbar[link].text}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
