// packages
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  MdLoupe,
  MdOutlineAddBox,
  MdOutlineAddShoppingCart,
  MdOutlineBookmark,
  MdOutlineBookmarkBorder,
  MdOutlineDisabledByDefault,
  MdOutlineImage,
  MdOutlineRemoveShoppingCart,
  MdOutlineShoppingCart,
} from "react-icons/md";

// components
import Alert from "./Alert";
import ItemDetails from "./ItemDetails";
import Modal from "./Modal";

// contexts
import { useComparison } from "../contexts/ComparisonContext";
import { useSearch } from "../contexts/SearchContext";

// utils
import { toggleBookmark } from "../utils/bookmark";
import { isInQuote, loadQuoteItems, toggleQuoteItem } from "../utils/quote";

export default function Table({
  data,
  columns,
  detailSettings,
  modalSettings,
  quoteType,
  enableSorting = false,
  showBookmarks,
}) {
  const navigate = useNavigate();

  //////////////////////////////////////////////////////////////////////////////
  // data
  //////////////////////////////////////////////////////////////////////////////

  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  //////////////////////////////////////////////////////////////////////////////
  // comparison
  //////////////////////////////////////////////////////////////////////////////

  const {
    comparisonData = [],
    comparisonType,
    toggleComparisonItem = () => {},
  } = useComparison() || {};

  const isInComparison = (item) => {
    return comparisonData.some((el) => el.id === item.id);
  };

  //////////////////////////////////////////////////////////////////////////////
  // click handlers
  //////////////////////////////////////////////////////////////////////////////

  const handleClickItem = (item, newWindow = false) => {
    let path;
    let route;

    if (item.path) {
      path = item.path;
    } else {
      path = columns.find((col) => col.path)?.path;
    }

    if (item.route) {
      route = item.route;
    } else if (path) {
      route = `${path}/${item.id}`;
    }

    if (!route) {
      return;
    }

    if (newWindow) {
      window.open(route, "_blank");
    } else {
      navigate(route);
    }
  };

  const handleClickRow = (e, item) => {
    // find parent TD element
    let target = e.target;
    while (target && target.nodeName !== "TD") {
      target = target.parentNode;
    }

    // error if TD element was not found
    if (!target || target.nodeName !== "TD") {
      // console.error("TD element not found");
      return;
    }

    // ignore if column specified so (for custom content like buttons)
    if (target.dataset.ignoreRowClick) {
      return;
    }

    // handle click event
    if (target.dataset.expandable) {
      if (detailSettings) {
        handleExpandDetails(item.id);
      } else {
        handleClickItem(item);
      }
    }
  };

  const handleExpandDetails = (itemId) => {
    const row = document.querySelector(`[data-details="${itemId}"]`);

    if (row) {
      if (row.classList.contains("hidden")) {
        row.classList.remove("hidden");
        row.classList.add("table-row");
      } else {
        row.classList.remove("table-row");
        row.classList.add("hidden");
      }
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  // contents
  //////////////////////////////////////////////////////////////////////////////

  const contentColumn = (column, item) => {
    return column.link ? (
      column.path ? (
        <a
          className="font-semibold text-theme-blue"
          href={`${column.path}/${item.id}`}
        >
          {_.get(item, column.key)}
        </a>
      ) : (
        <a
          className="font-semibold text-theme-blue"
          href={`${item.path}/${item.id}`}
        >
          {_.get(item, column.key)}
        </a>
      )
    ) : column.content ? (
      column.content
    ) : (
      _.get(item, column.key)
    );
  };

  //////////////////////////////////////////////////////////////////////////////
  // details
  //////////////////////////////////////////////////////////////////////////////

  const colSpanConditions = [
    modalSettings,
    comparisonType,
    quoteType,
    showBookmarks,
  ];

  let detailsRowColSpan =
    columns.length +
    colSpanConditions.reduce((acc, condition) => acc + (condition ? 1 : 0), 0);

  //////////////////////////////////////////////////////////////////////////////
  // modal
  //////////////////////////////////////////////////////////////////////////////

  const [modalData, setModalData] = useState(null);

  const openModal = (dataModal) => {
    setModalData(dataModal);
  };

  const closeModal = () => {
    setModalData(null);
  };

  //////////////////////////////////////////////////////////////////////////////
  // sorting
  //////////////////////////////////////////////////////////////////////////////

  const { getSortIcon = () => {}, handleSort = () => {} } = useSearch() || {};

  //////////////////////////////////////////////////////////////////////////////
  // bookmarks
  //////////////////////////////////////////////////////////////////////////////

  const toggleBookmarkWrapper = async (item) => {
    try {
      if (item.type === "bookmark") {
        const removedItem = await toggleBookmark(item.item);

        setTableData((prevData) => {
          return prevData.filter((prevItem) => prevItem.id !== removedItem.id);
        });
      } else {
        item = await toggleBookmark(item);

        setTableData((prevData) => {
          return prevData.map((bookmarkedItem) =>
            bookmarkedItem.id === item.id ? item : bookmarkedItem,
          );
        });
      }
    } catch (error) {
      console.error("Error toggling bookmark", error);
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  // quote
  //////////////////////////////////////////////////////////////////////////////

  const [quoteItems, setQuoteItems] = useState(loadQuoteItems());

  const handleToggleQuote = (item) => {
    toggleQuoteItem(quoteType, item);
    setQuoteItems(loadQuoteItems());
  };

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  if (data.length === 0) {
    return <Alert content="No results found" type="warning" visible={true} />;
  }

  return (
    <div className="my-2 overflow-x-auto md:my-4">
      <table className="w-full table-auto border border-black/10 text-left text-sm">
        <thead className="bg-gray-50 text-gray-800">
          <tr>
            {modalSettings && <th className="w-8"></th>}
            {comparisonType && (
              <th className="w-8 border-r border-black/10 px-2">
                <span className="hidden md:block">Compare</span>
                <MdOutlineAddBox className="hidden max-md:block" size="18" />
              </th>
            )}
            {columns.map((column, index) => (
              <th
                key={index}
                className={`
                  ${column.classNames && column.classNames}
                  ${enableSorting && "cursor-pointer"}
                  whitespace-nowrap border-r border-black/10 px-2 py-4
                `}
                onClick={() =>
                  enableSorting && handleSort(column.sortKey || column.key)
                }
                scope="col"
              >
                <span
                  className={`${
                    enableSorting && "flex items-center justify-start gap-1"
                  }`}
                >
                  {enableSorting &&
                    getSortIcon &&
                    getSortIcon(column.sortKey || column.key)}
                  {column.label}
                </span>
              </th>
            ))}
            {showBookmarks && <th className="w-8"></th>}
            {quoteType && (
              <th className="w-12 text-right">
                <button
                  className={`${
                    quoteItems.length > 0
                      ? "bg-theme-blue"
                      : "cursor-not-allowed bg-gray-600	"
                  } whitespace-nowrap rounded-md px-2 py-1 font-bold text-white`}
                  onClick={() => {
                    if (quoteItems.length > 0) {
                      navigate("/quotes/cart");
                    }
                  }}
                  title={
                    quoteItems.length > 0
                      ? "View quote cart"
                      : "Add some items to quote cart first"
                  }
                >
                  {quoteItems.length}
                  <MdOutlineShoppingCart
                    className="ml-1 inline-block shrink-0"
                    size="16"
                  />
                </button>
              </th>
            )}
            {/* {comparisonType && <th className="w-12"></th>} */}
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <React.Fragment key={index}>
              <tr
                key={index}
                className={`${
                  isInComparison(item)
                    ? "bg-theme-green-light/60 hover:bg-theme-green-light"
                    : `${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      } hover:bg-gray-200`
                } cursor-pointer`}
                data-id={item.id}
                data-type={item.type}
                onClick={(e) => handleClickRow(e, item)}
              >
                {modalSettings && (
                  <td className="text-center">
                    <button onClick={() => openModal(item)} type="button">
                      <MdLoupe
                        className="shrink-0 text-theme-blue"
                        size="16"
                        title="Quick view"
                      />
                    </button>
                  </td>
                )}
                {comparisonType && (
                  <td className="border-r border-black/10 pt-2 text-center align-middle">
                    <button
                      onClick={() => toggleComparisonItem(item)}
                      title={`${
                        isInComparison(item) ? "Remove from" : "Add to"
                      } comparison`}
                    >
                      {isInComparison(item) ? (
                        <MdOutlineDisabledByDefault
                          className="shrink-0 text-theme-red"
                          size="18"
                        />
                      ) : (
                        <MdOutlineAddBox
                          className="shrink-0 text-theme-blue"
                          size="18"
                        />
                      )}
                    </button>
                  </td>
                )}
                {columns.map((column, index) => (
                  <td
                    key={index}
                    className={`${
                      column.classNames ?? ""
                    } whitespace-nowrap border-r border-black/10  px-3 py-2 !text-left`}
                    data-expandable={true}
                    data-ignore-row-click={column.ignoreRowClick}
                  >
                    {contentColumn(column, item)}
                  </td>
                ))}
                {showBookmarks && (
                  <td className="text-center">
                    <button
                      onClick={() => toggleBookmarkWrapper(item)}
                      title={`${
                        (item.type === "bookmark" && item.item.is_bookmarked) ||
                        item.is_bookmarked
                          ? "Remove from"
                          : "Add to"
                      } bookmarks`}
                    >
                      {(item.type === "bookmark" && item.item.is_bookmarked) ||
                      item.is_bookmarked ? (
                        <MdOutlineBookmark
                          className="shrink-0 text-theme-blue"
                          size="16"
                        />
                      ) : (
                        <MdOutlineBookmarkBorder
                          className="shrink-0"
                          size="16"
                        />
                      )}
                    </button>
                  </td>
                )}
                {quoteType && (
                  <td className="pr-2 text-right">
                    <button
                      onClick={() => handleToggleQuote(item)}
                      title={`${
                        isInQuote(quoteType, item) ? "Remove from" : "Add to"
                      } quote`}
                    >
                      {isInQuote(quoteType, item) ? (
                        <MdOutlineRemoveShoppingCart
                          className="shrink-0 text-theme-red"
                          size="16"
                        />
                      ) : (
                        <MdOutlineAddShoppingCart
                          className="shrink-0 text-theme-blue"
                          size="16"
                        />
                      )}
                    </button>
                  </td>
                )}
              </tr>
              {detailSettings && (
                <tr
                  className="hidden cursor-pointer"
                  data-details={item.id}
                  onClick={() => {
                    handleClickItem(item, true);
                  }}
                >
                  <td
                    className="border border-black/10"
                    colSpan={detailsRowColSpan}
                  >
                    <div className="grid grid-cols-6 gap-0">
                      <div className="rounded-0 flex items-center justify-center bg-white p-0">
                        {item.images && item.images.thumb ? (
                          <img
                            alt={item.title}
                            className="object-cover"
                            loading="lazy"
                            src={item.images.thumb.src}
                          />
                        ) : (
                          <MdOutlineImage className="h-1/2 w-1/2 shrink-0 text-gray-400" />
                        )}
                      </div>
                      <ItemDetails
                        item={item}
                        keys={detailSettings.keys}
                        gridClass="col-span-5"
                      />
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {modalSettings && (
        <Modal
          data={modalData}
          isOpen={modalData !== null}
          onClose={closeModal}
          settings={modalSettings}
        />
      )}
    </div>
  );
}
